import { Component,Input,OnInit,ViewChild,ElementRef } from '@angular/core';
import { MeroDuniyaService } from '../../../../mero-duniya.service';
import {Router} from "@angular/router";
import 'rxjs/add/observable/of';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {DefaultLayoutComponent} from '../../../../containers/default-layout/default-layout.component';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit {

  constructor(private MeroDuniyaService:MeroDuniyaService,private router: Router,public DefaultLayoutComponent:DefaultLayoutComponent) { }

  public hideSuccessMessage = true;
  public Message;
  public hideErrorMessage = true;
  public subscriptionData;

  displayedColumns = ["name","price","description","action"];

  @ViewChild("mainTablePaginator", {static: true}) mainTablePaginator: MatPaginator;
  @ViewChild("mainTableSort", {static: true}) mainTableSort: MatSort;

  ngOnInit() {
    this.DefaultLayoutComponent.hideDefaultSpinner(false);
    var params={};
    this.MeroDuniyaService.getSubscription(params).subscribe((subscriptionData)=>{
      this.DefaultLayoutComponent.hideDefaultSpinner(true);
      this.subscriptionData = subscriptionData["subscriptionData"];
      this.subscriptionData = new MatTableDataSource(this.subscriptionData);
      this.subscriptionData.paginator = this.mainTablePaginator;
      this.subscriptionData.sort = this.mainTableSort;
    })
  }

  applyFilter(filterValue: string) {
    this.subscriptionData.filter = filterValue.trim().toLowerCase();    
  }

  public editSubscription(subscriptionData){
    this.MeroDuniyaService.setSubscriptionDataForEdit(subscriptionData);
    this.router.navigate(['/editSubscription']);
  }

  public deleteSubscription(id){
    let params={
      id:id,    
    }
    this.MeroDuniyaService.deleteSubscription(params).subscribe((response)=>{
      if(response["status"] == 1){
        this.hideSuccessMessage = false;
        this.Message = response["message"];
        setTimeout(()=> {
          this.Message  = null;
          this.hideSuccessMessage = true;
        }, 2000);
      }
      else{
        this.hideErrorMessage = false;
        this.Message = response["message"];
        setTimeout(()=>{
          this.Message  = null;
          this.hideErrorMessage = true;
        }, 3000);
      }
      this.ngOnInit();
    })
  }

  public unblockSubscription(id){
    let params={
      id:id,    
    }
    this.MeroDuniyaService.unblockSubscription(params).subscribe((response)=>{
      if(response["status"] == 1){
        this.hideSuccessMessage = false;
        this.Message = response["message"];
        setTimeout(()=> {
          this.Message  = null;
          this.hideSuccessMessage = true;
        }, 2000);
      }
      else{
        this.hideErrorMessage = false;
        this.Message = response["message"];
        setTimeout(()=>{
          this.Message  = null;
          this.hideErrorMessage = true;
        }, 3000);
      }
      this.ngOnInit();
    })
  }

  public blockSubscription(id){
    let params={
      id:id,    
    }
    this.MeroDuniyaService.blockSubscription(params).subscribe((response)=>{
      if(response["status"] == 1){
        this.hideSuccessMessage = false;
        this.Message = response["message"];
        setTimeout(()=> {
          this.Message  = null;
          this.hideSuccessMessage = true;
        }, 2000);
      }
      else{
        this.hideErrorMessage = false;
        this.Message = response["message"];
        setTimeout(()=>{
          this.Message  = null;
          this.hideErrorMessage = true;
        }, 3000);
      }
      this.ngOnInit();
    })
  }

}
