import { Component, OnInit } from '@angular/core';
import { MeroDuniyaService } from '../../mero-duniya.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public hideSuccessMessage = true;
  public Message;
  public hideErrorMessage = true;
  public userData;
  model: any = {};
  data: any = {};
  public firstName;
  public lastName;
  public email;
  public contact;
  constructor(private MeroDuniyaService:MeroDuniyaService,private router: Router) {
   }

  ngOnInit() {
    this.data = JSON.parse(this.MeroDuniyaService.getProfileDataForEdit());
    var params = {
      userId: this.data.userId
    }
    this.MeroDuniyaService.getProfile(params).subscribe((userData)=>{
      console.log("UserData666666",userData)
      this.model = userData;
      this.firstName = this.model.firstName;
      this.lastName = this.model.lastName;
      this.email = this.model.email;
      this.contact = this.model.contact;
      console.log("this.model",this.model)
    })
    // this.model = JSON.parse(this.MeroDuniyaService.getProfileDataForEdit());
    // this.firstName = this.model.firstName
    // console.log("this.modelsssss",this.model,this.MeroDuniyaService.getProfileDataForEdit())
  }

  public editProfile(firstName,lastName,email,contact){  
    var params = {
      _id:this.data.userId,
      firstName:firstName,
      lastName:lastName,
      email:email.toLowerCase(),
      contact:contact
  }
    this.MeroDuniyaService.editProfile(params).subscribe((response)=>{
        if(response["status"] == 1){
            this.hideSuccessMessage = false;
            this.Message = response["message"];
            setTimeout( () =>{
                this.Message  = null;
                this.hideSuccessMessage = true;
                this.router.navigate(['/']);
            }, 2000);
            
        }
        else{
            this.hideErrorMessage = false;
            this.Message = response["message"];
            setTimeout(()=> {
                this.Message  = null;
            }, 3000);
        }
    })
}

}
