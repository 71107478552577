import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { MeroDuniyaService } from '../../../mero-duniya.service';

@Component({
  selector: 'app-thankyou-page',
  templateUrl: './thankyou-page.component.html',
  styleUrls: ['./thankyou-page.component.scss']
})
export class ThankyouPageComponent implements OnInit {

  constructor(private router: Router,private MeroDuniyaService:MeroDuniyaService) { }

  public reset;
  
  ngOnInit() {
    this.reset = this.MeroDuniyaService.getThankyouMessage();
  }

}
