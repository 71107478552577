import { Component, OnDestroy, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { navItems } from '../../_nav';
import { MeroDuniyaService } from '../../mero-duniya.service';
import {Router} from "@angular/router"


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnDestroy {
  public userPermissionsData; 
  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  public hideSpinner;
  public loginSuccessfullMessage;

  constructor(private MeroDuniyaService:MeroDuniyaService,private router: Router,@Inject(DOCUMENT) _document?: any) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });
  }

  
  ngOnInit() {
    this.hideSpinner = true;
    this.userPermissionsData = JSON.parse(this.MeroDuniyaService.getUserPermissionsData());
    this.loginSuccessfullMessage = this.MeroDuniyaService.getDashboardMessage();
    this.MeroDuniyaService.deleteDashboardMessage();
    setTimeout(()=> {
      this.loginSuccessfullMessage  = null;
    }, 5000);
    if(this.userPermissionsData["role"]){
      if(this.userPermissionsData["role"] != "Admin"){
        const dashboardItem = navItems.find(({id}) => {return id === 'Dashboard'});
        this.navItems = this.navItems.filter((navItem)=>{
          let permission = this.userPermissionsData["permissions"].find((permission)=>{
            // console.log("navitemssss", navItem["id"]);
            // console.log("navitemssss", permission["privileges"]);
            return permission["privileges"] == navItem["id"];
          })
          
          if(permission){
            return navItem;
          }
          // navItem.push("Dashboard");
          // console.log("navitemssss", this.navItems);
        })
       this.navItems.length > 0 ? this.navItems.unshift(dashboardItem) : [];
      //  console.log(this.navItems);
      }
    }
    else{
      this.logout();
    }
  }

  public hideDefaultSpinner(hideSpinner){
    this.hideSpinner = hideSpinner;
  } 

  public logout(){
    this.MeroDuniyaService.logout().subscribe((response)=>{
      this.MeroDuniyaService.deleteToken();
      this.MeroDuniyaService.setLoginMessage({status:1,message:"You are successfully logged out."});
      this.router.navigate(['login']);
    })
  }

  public editProfile(){
      this.router.navigate(['editProfile']);
  }

  public changePassword(){
    this.router.navigate(['changePassword']);
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
}
