import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl,NgModel } from '@angular/forms';

@Directive({
  selector: 'input[charactersOnly]',
  providers: [NgModel],
  host: {
    '(ngModelChange)' : 'onInputChange($event)'
  }
})
export class CharactersOnlyDirectiveDirective {
  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    this._el.nativeElement.value = initalValue.replace(/[^a-zA-Z0-9 ]*/g, '').replace(/^[^a-zA-Z0-9]/g, '').replace('  ',' ');
    // this._el.nativeElement.value = initalValue.replace(/^[^a-zA-Z0-9]/g, '').replace('  ',' ');
    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
