import { Component, OnInit } from '@angular/core';
import { MeroDuniyaService } from '../../../mero-duniya.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public hideSuccessMessage = true;
  public Message;
  public hideErrorMessage = true;
  public userData;
  model: any = {};

  constructor(private MeroDuniyaService:MeroDuniyaService,private router: Router) { }

  ngOnInit() {
    this.model = JSON.parse(this.MeroDuniyaService.getProfileDataForEdit());
  }

  public changePassword(password,newPassword,confirmNewPassword){
    var params = {
      userId:this.model.userId,
      password:password,
      newPassword:newPassword,
      confirmNewPassword:confirmNewPassword,
   }
   this.MeroDuniyaService.changePassword(params).subscribe((response)=>{ 
        if(response["code"] == 200){
          this.hideSuccessMessage = false;
          this.Message = response["data"].message;
          setTimeout( () =>{
              this.Message  = null;
              this.hideSuccessMessage = true;
              this.router.navigate(['/']);
          }, 2000);
          
      }
      else{
          this.hideErrorMessage = false;
          this.Message = response["error"].message;
          setTimeout(()=> {
              this.Message  = null;
              this.hideErrorMessage = true;
          }, 3000);
      }
   });


  }

}
