interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
  id?:String;
}

export const navItems: NavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    id:"Dashboard",
    icon: 'icon-speedometer',
  },
  {
    name: 'User Management',
    url: '/userDetails',
    id:"Manage Users",
    icon: 'fa fa-users'
  },
  {
    name: 'Realtor Management',
    url: '/realtorDetails',
    id:"Manage Realtors",
    icon: 'fa fa-home'
  },
  {
    name: 'Staff Management',
    url: '/staff',
    id:"Manage Staff",
    icon: 'fa fa-user'
  },
  {
    name: 'Property Management',
    url: '/propertyDetails',
    id:"Manage Properties",
    icon: 'fa fa-building'
  },
  {
    name: 'Monthly Subscription Plan Management',
    url: '/subscription',
    id:"Manage Subscription",
    icon: 'fa fa-fire'
  },
  {
    name: 'Transaction Reports',
    url: '/transactionReport',
    id:"Manage Transaction",
    icon: 'fa fa-file'
  },
  {
    divider: true
  },
];
