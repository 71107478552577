import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CKEditorModule } from 'ngx-ckeditor';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MeroDuniyaService } from './mero-duniya.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './tokenInterceptor';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'; 
import {DataTableModule} from "angular-6-datatable";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import {MatTableModule} from '@angular/material'
import { MatPaginatorModule } from '@angular/material';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule,MatInputModule} from '@angular/material'
import { MatSortModule } from '@angular/material';
import { AngularFileUploaderModule } from "angular-file-uploader";
import { NgSelectModule } from '@ng-select/ng-select';
import { TruncateModule } from 'ng2-truncate';
import { PhonePipe } from './customPipes/pipePhoneFormat';
import { NgxSmartModalModule } from 'ngx-smart-modal'; 
// import { MatSelectFilterModule } from 'mat-select-filter';
import { MatSelectModule} from '@angular/material';
import { PapaParseModule } from 'ngx-papaparse';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';







const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { NgInitDirective } from './directives/ng-init-directive';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
  
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';

import { NumbersOnlyDirective } from './directives/numberOnlyDirective/numbers-only.directive';
import { CharactersOnlyDirectiveDirective } from './directives/charactersOnlyDirective/characters-only-directive.directive';
import { ProfileComponent } from './views/profile/profile.component';
import { PropertyDetailsComponent } from './views/admin/property/property-details/property-details.component';
import { EditPropertyDetailsComponent } from './views/admin/property/edit-property-details/edit-property-details.component';
import { UserDetailsComponent } from './views/admin/Users/user-details/user-details.component';
import { ViewUserDetailsComponent } from './views/admin/Users/view-user-details/view-user-details.component';
import { RealtorDetailsComponent } from './views/admin/realtors/realtor-details/realtor-details.component';
import { ViewRealtorDetailsComponent } from './views/admin/realtors/view-realtor-details/view-realtor-details.component';
import { CreateStaffComponent } from './views/admin/staff/create-staff/create-staff.component';
import { StaffComponent } from './views/admin/staff/staff/staff.component';
import { EditStaffComponent } from './views/admin/staff/edit-staff/edit-staff.component';
import { ViewStaffComponent } from './views/admin/staff/view-staff/view-staff.component';
import { SubscriptionComponent } from './views/admin/subscription/subscription/subscription.component';
import { EditSubscriptionComponent } from './views/admin/subscription/edit-subscription/edit-subscription.component';
import { NoSpaceDirectiveDirective } from './directives/noSpaceDirective/no-space-directive.directive';
import { TransactionReportComponent } from './views/admin/transacton-report/transaction-report/transaction-report.component';
import { NumberOnlyPeriodDirective } from './directives/numberOnlyPeriod/number-only-period.directive';
import { ChangePasswordComponent } from './views/change-password/change-password/change-password.component';
import { ThankyouPageComponent } from './views/thanks_page/thankyou-page/thankyou-page.component';







@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTableModule,
    GooglePlaceModule,
    MatTableModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    AngularFileUploaderModule,
    NgSelectModule,
    TruncateModule,
    NgxSmartModalModule.forRoot(),
    // MatSelectFilterModule,
    MatSelectModule,
    PapaParseModule,
    CKEditorModule,
    MatDatepickerModule,
    MatNativeDateModule

  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PhonePipe,
    NgInitDirective,
    NumbersOnlyDirective,
    CharactersOnlyDirectiveDirective,
    ProfileComponent,
    PropertyDetailsComponent,
    EditPropertyDetailsComponent,
    UserDetailsComponent,
    ViewUserDetailsComponent,
    RealtorDetailsComponent,
    ViewRealtorDetailsComponent,
    CreateStaffComponent,
    StaffComponent,
    EditStaffComponent,
    ViewStaffComponent,
    SubscriptionComponent,
    EditSubscriptionComponent,
    NoSpaceDirectiveDirective,
    TransactionReportComponent,
    NumberOnlyPeriodDirective,
    ChangePasswordComponent,
    ThankyouPageComponent
  ],
  entryComponents: [],
  providers: [{
    provide: LocationStrategy,
    useClass: PathLocationStrategy,
  },
    MeroDuniyaService,
    DefaultLayoutComponent,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
