import { Injectable } from '@angular/core';
import {HttpRequest,HttpHandler,HttpEvent,HttpInterceptor,HttpResponse,HttpErrorResponse} from '@angular/common/http';
import { MeroDuniyaService } from './mero-duniya.service';
import { Observable } from 'rxjs';
import {Router} from "@angular/router"
import { tap } from "rxjs/operators";




@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  
  constructor(private MeroDuniyaService:MeroDuniyaService,private router: Router) {}
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    request = request.clone({
      setHeaders: {
        Token: `${this.MeroDuniyaService.getUserToken()}`
      }
    });
    
    return next.handle(request).pipe(tap((err: any) => {
      if (event instanceof HttpResponse) {
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.MeroDuniyaService.deleteToken();
          this.router.navigate(['login']);
        }
      }
    }));
  }
}