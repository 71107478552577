import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { MeroDuniyaService } from '../../mero-duniya.service';
import {Router} from "@angular/router";


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private route: ActivatedRoute,private MeroDuniyaService:MeroDuniyaService,private router: Router) { }
  public resetToken;
  public showReset;
  public disableLoginButton = false;
  public userId;
  public hideSuccessMessage = true;
  public Message;
  public hideErrorMessage = true;

  ngOnInit() {
    this.resetToken = this.route.snapshot.paramMap.get("resetToken");
    var params={
      token : this.resetToken
    }
    this.MeroDuniyaService.checkResetPasswordToken(params).subscribe((response)=>{
      if(response['userId'] && response['status'] == 1){
        this.showReset = true;
        this.userId = response['userId'];
      }
      else{
        this.showReset = false;
      }
    })
  }

  public resetPassword(password,confirmPassword){
    this.disableLoginButton = true;
    if((password == confirmPassword) && (password != "") && (password != "undefined") && (password != null)){
      var params = {
        password : password,
        userId : this.userId,
        token : this.resetToken
      }
      this.MeroDuniyaService.resetPassword(params).subscribe((response)=>{
        if(response['status'] == 1 ){
          // this.disableLoginButton = true;
          // this.hideSuccessMessage = false;
          // this.Message = response["message"];
          this.showReset = true;
          this.MeroDuniyaService.setThankyouMessage("reset");
          this.router.navigate(['/thankYou']);
          // setTimeout( () =>{
          //   this.Message  = null;
          //   this.hideSuccessMessage = true;
          //   this.router.navigate(['/login']);
          // }, 2000);
        }
        else{
          this.disableLoginButton = false;
          this.hideErrorMessage = false;
          this.Message = response["message"];
          setTimeout(()=> {
            this.Message  = null;
            this.hideErrorMessage = true;
          }, 3000);
        }
      })
    } 
    else if(password != confirmPassword){
      this.disableLoginButton = false;
      this.hideErrorMessage = false;
      this.Message = "Password does not match with confirm password.";
      setTimeout(()=> {
        this.Message  = null;
        this.hideErrorMessage = true;
      }, 3000); 
    }
    else{
        this.disableLoginButton = false;
          this.hideErrorMessage = false;
          this.Message = "Please enter password/confirm password";
          setTimeout(()=> {
            this.Message  = null;
            this.hideErrorMessage = true;
          }, 3000);   
     }
  }

}
