import { Component, OnInit } from '@angular/core';
import { MeroDuniyaService } from '../../mero-duniya.service';
import {Router} from "@angular/router";


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  
  constructor(private MeroDuniyaService:MeroDuniyaService,private router: Router) { }
  public hideSuccessMessage = true;
  public Message;
  public hideErrorMessage = true;
  public model:any = {};
  public disableLoginButton = false;

  ngOnInit() {
    let token = this.MeroDuniyaService.getUserToken();
    console.log("token is",token);
    if(token){
      this.router.navigate(['/dashboard'])
    }
  }

  public showErrorMessage(message){
    this.hideErrorMessage = false;
          this.Message = message;
          setTimeout(()=> {
            this.Message  = null;
          }, 3000);
  }

  public validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  public forgotPassword(email){
    if(!email){
      this.showErrorMessage("Please enter Email address.")
    }
    else if(!this.validateEmail(email)){
      this.showErrorMessage("User not found. Please check and try again.")
    }
    else{
      var params = {
        email:email
      }
      this.MeroDuniyaService.forgotPasswordForWeb(params).subscribe((response)=>{
        this.disableLoginButton = false;
        if(response["message"] == "Mail Sent"){
          this.disableLoginButton = true;
          this.hideSuccessMessage = false;
          this.Message = "Your request for reset password is successfully sent on your registered email id.";
          setTimeout( () =>{
            this.Message  = null;
            this.hideSuccessMessage = true;
            this.router.navigate(['/login']);
          }, 2000);
        }
        else{
          this.disableLoginButton = false;
          this.hideErrorMessage = false;
          this.Message = response["message"];
          setTimeout(()=> {
            this.Message  = null;
            this.hideErrorMessage = true;
          }, 3000);
        }
      })     
    }
  } 
}
