import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { MeroDuniyaService } from '../../../../mero-duniya.service';
import { ActivatedRoute } from "@angular/router";
import {DefaultLayoutComponent} from '../../../../containers/default-layout/default-layout.component';


@Component({
  selector: 'app-edit-staff',
  templateUrl: './edit-staff.component.html',
  styleUrls: ['./edit-staff.component.scss']
})
export class EditStaffComponent implements OnInit {

  public hideSuccessMessage = true;
  public Message;
  public hideErrorMessage = true;
  public suburb;
  public zipCode;
  public state;
  public streetName;
  public fullAddress;
  public jobAddress;
  public country;
  model: any = {};
  public lng;
  public lat;
  public privileges;
  public disableInput = false;
  
  constructor(private MeroDuniyaService:MeroDuniyaService,private router: Router,private route: ActivatedRoute,public DefaultLayoutComponent:DefaultLayoutComponent) { }

  ngOnInit() {
    this.model = JSON.parse(this.MeroDuniyaService.getStaffDetailsDataForEdit());
    this.privileges = [{privileges:'Manage Users'},{privileges:'Manage Realtors'},{privileges:'Manage Properties'}];
    this.streetName = this.model.streetName;
    this.suburb = this.model.suburb;
    this.state = this.model.state;
    this.zipCode = this.model.zipCode;
    this.country = this.model.country;
    this.jobAddress = this.model.streetName;
  }

  // public getAddress(address){
  //   var addressObject = this.MeroDuniyaService.getAddress(address);
  //   this.streetName = ((addressObject[0] && addressObject[0]["long_name"] && addressObject[1] && addressObject[1]["long_name"])?addressObject[0]["long_name"]+addressObject[1]["long_name"]:null);
  //   this.suburb = ((addressObject[2] && addressObject[2]["long_name"])?addressObject[2]["long_name"]:null);
  //   this.state = ((addressObject[3] && addressObject[3]["long_name"])?addressObject[3]["long_name"]:null);
  //   this.zipCode = ((addressObject[4] && addressObject[4]["long_name"])?addressObject[4]["long_name"]:null);
  //   this.fullAddress = ((addressObject[5])?addressObject[5]:null);
  //   this.country = ((addressObject[6] && addressObject[6]["long_name"])?addressObject[6]["long_name"]:null);
  //   this.lng = ((addressObject[8])?addressObject[8]:null);
  //   this.lat = ((addressObject[7])?addressObject[7]:null);
  // }

  public editStaff(firstName,lastName,email,contact,assignedPrivileges){
    this.disableInput = true;
    this.DefaultLayoutComponent.hideDefaultSpinner(false);
    if(!this.lng && !this.lat){
      this.lng = 0;
      this.lat = 0;
    };

    var params = {
      id:this.model._id ,
      firstName:firstName,
      lastName: lastName,
      email:email,
      contact:contact,
      permissions: assignedPrivileges,
      userId : this.MeroDuniyaService.getUserID(),
     
  }
    this.MeroDuniyaService.editStaff(params).subscribe((response)=>{
      this.DefaultLayoutComponent.hideDefaultSpinner(true);
        if(response["status"] == 1){
            this.hideSuccessMessage = false;
            this.Message = response["message"];
            setTimeout( () =>{
                this.Message  = null;
                this.hideSuccessMessage = true;
                this.router.navigate(['/staff']);
            }, 2000);
            
        }
        else{
            this.disableInput = false;
            this.hideErrorMessage = false;
            this.Message = response["message"];
            setTimeout(()=> {
                this.Message  = null;
            }, 3000);
        }
    })
}

}
