import { Component,Input,OnInit,ViewChild,ElementRef } from '@angular/core';
import { MeroDuniyaService } from '../../../../mero-duniya.service';
import {Router} from "@angular/router";
import 'rxjs/add/observable/of';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {DefaultLayoutComponent} from '../../../../containers/default-layout/default-layout.component';
import * as alasql from 'alasql';

@Component({
  selector: 'app-transaction-report',
  templateUrl: './transaction-report.component.html',
  styleUrls: ['./transaction-report.component.scss']
})
export class TransactionReportComponent implements OnInit {

  constructor(private MeroDuniyaService:MeroDuniyaService,private router: Router,public DefaultLayoutComponent:DefaultLayoutComponent) { }
  public hideSuccessMessage = true;
  public Message;
  public hideErrorMessage = true;
  public subscriedUserData;

  displayedColumns = ["firstName","lastName","email","status","price","subscriptionDate"];
  
  displayedCSVColumns = ["firstName","lastName","email","status","price","subscriptionDate"];

  @ViewChild("mainTablePaginator", {static: true}) mainTablePaginator: MatPaginator;
  @ViewChild("mainTableSort", {static: true}) mainTableSort: MatSort;

  ngOnInit() {
    this.DefaultLayoutComponent.hideDefaultSpinner(false);
    this.MeroDuniyaService.getSubscriedUserForWeb().subscribe((subscriedUserData)=>{
      console.log("Subscribed Data",subscriedUserData)
      this.DefaultLayoutComponent.hideDefaultSpinner(true);
      this.subscriedUserData = subscriedUserData["subscriedUserData"];
      this.subscriedUserData = new MatTableDataSource(this.subscriedUserData);
      this.subscriedUserData.paginator = this.mainTablePaginator;
      this.subscriedUserData.sort = this.mainTableSort;
    })
  }

  applyFilter(filterValue: string) {
    this.subscriedUserData.filter = filterValue.trim().toLowerCase();    
  }

  public exportTransaction(){
    console.log("filtered data",this.subscriedUserData.filteredData);
    let exportTransactionData = this.subscriedUserData.filteredData.map(subscriedUserData=>({ 
      "First Name":subscriedUserData.userId? (subscriedUserData.userId.firstName): "",
      "Last Name" : subscriedUserData.userId? (subscriedUserData.userId.lastName): "",
      "Email":subscriedUserData.userId? (subscriedUserData.userId.email): "",
      "Status":subscriedUserData.userId? (subscriedUserData.userId.status): "",
      "Price":"3.99",
      "Subscription Date": subscriedUserData.created_at
    }));
    if(!exportTransactionData.length){
      exportTransactionData[0] ={
        "First Name":"",
        "Last Name" : "",
        "Email":"",
        "Status":"",
        "Price":"",
        "Subscription Date":""
      }
    }
    console.log("data is",this.subscriedUserData.filteredData,exportTransactionData);
    alasql('SELECT * INTO   CSV("Transaction.xls",{headers:true,separator:","}) FROM ?',[exportTransactionData]);
  }

}
