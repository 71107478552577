import { Component,Input,OnInit,ViewChild,ElementRef } from '@angular/core';
import { MeroDuniyaService } from '../../../../mero-duniya.service';
import {Router} from "@angular/router";
import 'rxjs/add/observable/of';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {DefaultLayoutComponent} from '../../../../containers/default-layout/default-layout.component';
import * as alasql from 'alasql';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {

  constructor(private MeroDuniyaService:MeroDuniyaService,private router: Router,public DefaultLayoutComponent:DefaultLayoutComponent) { }

  public hideSuccessMessage = true;
  public Message;
  public hideErrorMessage = true;
  public showCreateJob = false;
  public userData;


  displayedColumns = ["firstName","lastName","email","contact","action"];
  displayedCSVColumns = ["firstName","lastName","email","contact","streetName","suburb","state","zipCode","country"];
  @ViewChild("mainTablePaginator", {static: true}) mainTablePaginator: MatPaginator;
  @ViewChild("mainTableSort", {static: true}) mainTableSort: MatSort;

  ngOnInit() {
    this.DefaultLayoutComponent.hideDefaultSpinner(false);
    var params={
      role:'staff'
    };
    this.MeroDuniyaService.getStaff(params).subscribe((userData)=>{
      this.DefaultLayoutComponent.hideDefaultSpinner(true);
      this.userData = userData["userData"];
      this.userData = new MatTableDataSource(this.userData);
      this.userData.paginator = this.mainTablePaginator;
      this.userData.sort = this.mainTableSort;
    }) 
  }

  applyFilter(filterValue: string) {
    this.userData.filter = filterValue.trim().toLowerCase();    
  }

  public editStaff(userData){
    this.MeroDuniyaService.setStaffDetailsDataForEdit(userData);
    this.router.navigate(['/editStaff']);
  }

  public viewStaff(userData){
    this.MeroDuniyaService.setStaffDetailsDataForEdit(userData);
    this.router.navigate(['/viewStaff']);
  }

  public deleteStaff(id){
    let params={
      id:id,    
    }
    this.MeroDuniyaService.deleteStaff(params).subscribe((response)=>{
      if(response["status"] == 1){
        this.hideSuccessMessage = false;
        this.Message = response["message"];
        setTimeout(()=> {
          this.Message  = null;
          this.hideSuccessMessage = true;
        }, 2000);
      }
      else{
        this.hideErrorMessage = false;
        this.Message = response["message"];
        setTimeout(()=>{
          this.Message  = null;
          this.hideErrorMessage = true;
        }, 3000);
      }
      this.ngOnInit();
    })
  }

  public unBlockUser(id){
    let params={
      id:id,    
    }
    this.MeroDuniyaService.unBlockUser(params).subscribe((response)=>{
      if(response["status"] == 1){
        this.hideSuccessMessage = false;
        this.Message = response["message"];
        setTimeout(()=> {
          this.Message  = null;
          this.hideSuccessMessage = true;
        }, 2000);
      }
      else{
        this.hideErrorMessage = false;
        this.Message = response["message"];
        setTimeout(()=>{
          this.Message  = null;
          this.hideErrorMessage = true;
        }, 3000);
      }
      this.ngOnInit();
    })
  }

  public blockUser(id){
    let params={
      id:id,    
    }
    this.MeroDuniyaService.blockUser(params).subscribe((response)=>{
      if(response["status"] == 1){
        this.hideSuccessMessage = false;
        this.Message = response["message"];
        setTimeout(()=> {
          this.Message  = null;
          this.hideSuccessMessage = true;
        }, 2000);
      }
      else{
        this.hideErrorMessage = false;
        this.Message = response["message"];
        setTimeout(()=>{
          this.Message  = null;
          this.hideErrorMessage = true;
        }, 3000);
      }
      this.ngOnInit();
    })
  }

  public exportUsers(){
    let exportUsersData = this.userData.filteredData.map(userData => ({ 
      "First Name": userData.firstName,
      "Last Name" : userData.lastName,
      "Email Address":userData.email,
      "Contact" : userData.contact,
      "Street Name":userData.streetName,
      "Suburb":userData.suburb,
      "State":userData.state,
      "ZipCode":userData.zipCode,
      "Country":userData.country
    }));
      if(!exportUsersData.length){
          exportUsersData[0] ={
          "First Name":"",
          "Last Name" :"",
          "Email Address":"",
          "Contact" :"",
          "Street Name":"",
          "Suburb":"",
          "State":"",
          "ZipCode":"",
          "Country":""
          }
      }
    console.log("data is",this.userData.filteredData,exportUsersData);
    alasql('SELECT * INTO   CSV("Staffs.csv",{headers:true,separator:","}) FROM ?',[exportUsersData]);
  }


}
