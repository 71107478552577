import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { MeroDuniyaService } from '../../../../mero-duniya.service';
import { ActivatedRoute } from "@angular/router";
import {DefaultLayoutComponent} from '../../../../containers/default-layout/default-layout.component';


@Component({
  selector: 'app-edit-property-details',
  templateUrl: './edit-property-details.component.html',
  styleUrls: ['./edit-property-details.component.scss']
})
export class EditPropertyDetailsComponent implements OnInit {

  constructor(private MeroDuniyaService:MeroDuniyaService,private router: Router,private route: ActivatedRoute,public DefaultLayoutComponent:DefaultLayoutComponent) { }
  public hideSuccessMessage = true;
  public Message;
  public hideErrorMessage = true;
  public suburb;
  public zipCode;
  public state;
  public streetName;
  public fullAddress;
  public country;
  model: any = {};
  public formData = new FormData;
  public lng;
  public lat;
  public uploadedFileName;
  public disableInput = false;
  public todayDate = new Date();
  public deletedImages: any = [];
  public displayImage = true;

  ngOnInit() {
    this.model = JSON.parse(this.MeroDuniyaService.getPropertyDetailsDataForEdit());
    this.suburb = this.model.suburb;
    this.state = this.model.state;
    this.zipCode = this.model.zipCode;
    this.country = this.model.country;
    this.model.fullAddress = this.model.fullAddress;
    for(var i= 0; i < this.model.propertyImages.length; i++) {
      this.model.baseUrl = this.MeroDuniyaService.getBaseUrl()+"/";
      this.model.logoLinks = this.model.propertyImages;
      this.uploadedFileName = this.model.propertyImages[i];
    }
  }

  

  public getLogo(logo:FileList){
    const files: File = logo.item(0);
    this.model['propertyImages'] = files;
  }

  public getAddress(address){
    var addressObject = this.MeroDuniyaService.getAddress(address);
    this.streetName = ((addressObject[0] && addressObject[0]["long_name"] && addressObject[1] && addressObject[1]["long_name"])?addressObject[0]["long_name"]+addressObject[1]["long_name"]:null);
    this.suburb = ((addressObject[2] && addressObject[2]["long_name"])?addressObject[2]["long_name"]:null);
    this.state = ((addressObject[3] && addressObject[3]["short_name"])?addressObject[3]["short_name"]:null);
    this.zipCode = ((addressObject[4] && addressObject[4]["long_name"])?addressObject[4]["long_name"]:null);
    this.model.fullAddress = ((addressObject[5])?addressObject[5]:null);
    this.country = ((addressObject[6] && addressObject[6]["long_name"])?addressObject[6]["long_name"]:null);
    this.lng = ((addressObject[8])?addressObject[8]:0);
    this.lat = ((addressObject[7])?addressObject[7]:0);
    this.model.jobAddress = ((addressObject[5])?addressObject[5]:null);
  }

  
  public editPropertyDetails(propertyTitle,propertyPrice,numberOfBeds,numberOfBaths,squareFeet,dateAvailable,endDateProperty,description,streetName,suburb,state,zipCode,country,fullAddress){
      var propertyPriceData = Number.parseFloat(propertyPrice).toFixed(2);
      var squareFeetData = Number.parseFloat(squareFeet).toFixed(2);
      this.disableInput = true;
      this.DefaultLayoutComponent.hideDefaultSpinner(false);
      this.formData = new FormData;
      this.formData.append("_id" ,this.model._id);
      this.formData.append("propertyTitle", propertyTitle);
      this.formData.append("propertyPrice", propertyPriceData);
      this.formData.append("numberOfBeds", numberOfBeds);
      this.formData.append("numberOfBaths", numberOfBaths);
      this.formData.append("squareFeet", squareFeetData);
      this.formData.append("dateAvailable", dateAvailable);
      this.formData.append("endDateProperty", endDateProperty);
      this.formData.append("description", description);
      // this.formData.append("userId" , this.MeroDuniyaService.getUserID());
      // this.formData.append("username" , this.MeroDuniyaService.getUserName());
      this.formData.append("state",state);
      this.formData.append("streetName",streetName);
      this.formData.append("suburb",suburb);
      this.formData.append("zipCode",zipCode);
      this.formData.append("country",country);
      this.formData.append("fullAddress",fullAddress);
      if(this.lat && this.lng){
        this.formData.append("lat",this.lat);
        this.formData.append("lng",this.lng); 
      }; 
      // this.formData.append("propertyImages", this.model['propertyImages']);  
      this.MeroDuniyaService.editPropertyDetails(this.formData).subscribe((response)=>{
        this.DefaultLayoutComponent.hideDefaultSpinner(true);
        if(response["status"] == 1){
          this.hideSuccessMessage = false;
          this.Message = response["message"];
          setTimeout(()=> {
            this.Message  = null;
            this.hideSuccessMessage = true;
            this.router.navigate(['/propertyDetails']);
          }, 2000);
          
        }
        else{
          this.disableInput = false;
          this.hideErrorMessage = false;
          this.Message = response["message"];
          setTimeout(()=>{
            this.Message  = null;
            this.hideErrorMessage = true;
          }, 3000);
        }
      })
    }

  //  public deleteImageFunction(imageUrl){
  //    this.deletedImages.push(imageUrl);
  //    this.displayImage = false;
  //    this.model['propertyImages']=this.model['propertyImages'].filter(e=>{
  //      return !(this.deletedImages.indexOf(e) > -1);
  //    });
  //  }
}
