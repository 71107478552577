import { Component,Input,OnInit,ViewChild,ElementRef } from '@angular/core';
import { MeroDuniyaService } from '../../../../mero-duniya.service';
import {Router} from "@angular/router";
import 'rxjs/add/observable/of';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {DefaultLayoutComponent} from '../../../../containers/default-layout/default-layout.component';
import * as alasql from 'alasql';

@Component({
  selector: 'app-realtor-details',
  templateUrl: './realtor-details.component.html',
  styleUrls: ['./realtor-details.component.scss']
})
export class RealtorDetailsComponent implements OnInit {

  constructor(private MeroDuniyaService:MeroDuniyaService,private router: Router,public DefaultLayoutComponent:DefaultLayoutComponent) { }

  public hideSuccessMessage = true;
  public Message;
  public hideErrorMessage = true;
  public showCreateJob = false;
  public userData;


  displayedColumns = ["firstName","lastName","email","contact","nrdsID","zipCode","action"];
  displayedCSVColumns = ["firstName","lastName","email","contact","nrdsID","zipCode"];
  @ViewChild("mainTablePaginator", {static: true}) mainTablePaginator: MatPaginator;
  @ViewChild("mainTableSort", {static: true}) mainTableSort: MatSort;

  ngOnInit() {
    this.DefaultLayoutComponent.hideDefaultSpinner(false); 
    var params={
      role:'realtor'
    };
    this.MeroDuniyaService.getRealtorDetails(params).subscribe((userData)=>{
      this.DefaultLayoutComponent.hideDefaultSpinner(true);
      this.userData = userData["userData"];
      this.userData = new MatTableDataSource(this.userData);
      this.userData.paginator = this.mainTablePaginator;
      this.userData.sort = this.mainTableSort;
    }) 
  }

  applyFilter(filterValue: string) {
    this.userData.filter = filterValue.trim().toLowerCase();    
  }

  public deleteRealtorDetails(id){
    let params={
      id:id,    
    }
    this.MeroDuniyaService.deleteRealtorDetails(params).subscribe((response)=>{
      if(response["status"] == 1){
        this.hideSuccessMessage = false;
        this.Message = response["message"];
        setTimeout(()=> {
          this.Message  = null;
          this.hideSuccessMessage = true;
        }, 2000);
      }
      else{
        this.hideErrorMessage = false;
        this.Message = response["message"];
        setTimeout(()=>{
          this.Message  = null;
          this.hideErrorMessage = true;
        }, 3000);
      }
      this.ngOnInit();
    })
  }

  public unblockRealtorDetails(id){
    let params={
      id:id,    
    }
    this.MeroDuniyaService.unblockRealtorDetails(params).subscribe((response)=>{
      if(response["status"] == 1){
        this.hideSuccessMessage = false;
        this.Message = response["message"];
        setTimeout(()=> {
          this.Message  = null;
          this.hideSuccessMessage = true;
        }, 2000);
      }
      else{
        this.hideErrorMessage = false;
        this.Message = response["message"];
        setTimeout(()=>{
          this.Message  = null;
          this.hideErrorMessage = true;
        }, 3000);
      }
      this.ngOnInit();
    })
  }

  public blockRealtorDetails(id){
    let params={
      id:id,    
    }
    this.MeroDuniyaService.blockRealtorDetails(params).subscribe((response)=>{
      if(response["status"] == 1){
        this.hideSuccessMessage = false;
        this.Message = response["message"];
        setTimeout(()=> {
          this.Message  = null;
          this.hideSuccessMessage = true;
        }, 2000);
      }
      else{
        this.hideErrorMessage = false;
        this.Message = response["message"];
        setTimeout(()=>{
          this.Message  = null;
          this.hideErrorMessage = true;
        }, 3000);
      }
      this.ngOnInit();
    })
  }

  public exportUsers(){
    let exportUsersData = this.userData.filteredData.map(userData => ({ 
      "First Name": userData.firstName,
      "Last Name" : userData.lastName,
      "Email":userData.email,
      "Contact" : userData.contact,
      "NRDS ID": userData.nrdsID,
      "Zip Code":userData.zipCode,
    }));
      if(!exportUsersData.length){
          exportUsersData[0] ={
          "First Name":"",
          "Last Name" :"",
          "Email":"",
          "Contact" :"",
          "NRDS ID": "",
          "Zip Code":""
          }
      }
    console.log("data is",this.userData.filteredData,exportUsersData);
    alasql('SELECT * INTO   CSV("Realtors.csv",{headers:true,separator:","}) FROM ?',[exportUsersData]);
  }


  public viewRealtorDetails(userData){
    this.MeroDuniyaService.setRealtorDetailsDataForEdit(userData);
    this.router.navigate(['/viewRealtorDetails']);
  }

}
