import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { MeroDuniyaService } from '../../../../mero-duniya.service';
import { ActivatedRoute } from "@angular/router";
import {DefaultLayoutComponent} from '../../../../containers/default-layout/default-layout.component';

@Component({
  selector: 'app-view-user-details',
  templateUrl: './view-user-details.component.html',
  styleUrls: ['./view-user-details.component.scss']
})
export class ViewUserDetailsComponent implements OnInit {

  constructor(private MeroDuniyaService:MeroDuniyaService,private router: Router,private route: ActivatedRoute,public DefaultLayoutComponent:DefaultLayoutComponent) { }

  public hideSuccessMessage = true;
  public Message;
  public hideErrorMessage = true;
  public suburb;
  public zipCode;
  public state;
  public streetName;
  public fullAddress;
  public country;
  model: any = {};

  ngOnInit() {
    this.model = JSON.parse(this.MeroDuniyaService.getUserDetailsDataForEdit());
    this.suburb = this.model.suburb;
    this.state = this.model.state;
    this.zipCode = this.model.zipCode;
    this.country = this.model.country;
    this.fullAddress = this.model.fullAddress;
  }

}
