import { Component,Input,OnInit,ViewChild,ElementRef } from '@angular/core';
import { MeroDuniyaService } from '../../../../mero-duniya.service';
import {Router} from "@angular/router";
import 'rxjs/add/observable/of';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {DefaultLayoutComponent} from '../../../../containers/default-layout/default-layout.component';
import * as alasql from 'alasql';

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss']
})
export class PropertyDetailsComponent implements OnInit {

  constructor(private MeroDuniyaService:MeroDuniyaService,private router: Router,public DefaultLayoutComponent:DefaultLayoutComponent) { }

  public hideSuccessMessage = true;
  public Message;
  public hideErrorMessage = true;
  public showCreateJob = false;
  public propertyData;

  displayedColumns = ["propertyTitle","propertyPrice","squareFeet","numberOfBeds","numberOfBaths","dateAvailable","endDateProperty","location","action"];

  displayedCSVColumns = ["propertyTitle","propertyPrice","squareFeet","numberOfBeds","numberOfBaths","dateAvailable","dateAvailable","endDateProperty","location"];

  @ViewChild("mainTablePaginator", {static: true}) mainTablePaginator: MatPaginator;
  @ViewChild("mainTableSort", {static: true}) mainTableSort: MatSort;

  ngOnInit() {
    this.DefaultLayoutComponent.hideDefaultSpinner(false);
    var params={};
    this.MeroDuniyaService.getPropertyDetailsDataForAdmin(params).subscribe((propertyData)=>{
      this.DefaultLayoutComponent.hideDefaultSpinner(true);
      this.propertyData = propertyData["propertyData"];
      this.propertyData = new MatTableDataSource(this.propertyData);
      this.propertyData.paginator = this.mainTablePaginator;
      this.propertyData.sort = this.mainTableSort;
    }) 
  }

  applyFilter(filterValue: string) {
    this.propertyData.filter = filterValue.trim().toLowerCase();    
  }

  public deletePropertyDetails(id){
    let params={
      id:id,    
    }
    this.MeroDuniyaService.deletePropertyDetails(params).subscribe((response)=>{
      if(response["status"] == 1){
        this.hideSuccessMessage = false;
        this.Message = response["message"];
        setTimeout(()=> {
          this.Message  = null;
          this.hideSuccessMessage = true;
        }, 2000);
      }
      else{
        this.hideErrorMessage = false;
        this.Message = response["message"];
        setTimeout(()=>{
          this.Message  = null;
          this.hideErrorMessage = true;
        }, 3000);
      }
      this.ngOnInit();
    })
  }

  public unblockPropertyDetails(id){
    let params={
      id:id,    
    }
    this.MeroDuniyaService.unblockPropertyDetails(params).subscribe((response)=>{
      if(response["status"] == 1){
        this.hideSuccessMessage = false;
        this.Message = response["message"];
        setTimeout(()=> {
          this.Message  = null;
          this.hideSuccessMessage = true;
        }, 2000);
      }
      else{
        this.hideErrorMessage = false;
        this.Message = response["message"];
        setTimeout(()=>{
          this.Message  = null;
          this.hideErrorMessage = true;
        }, 3000);
      }
      this.ngOnInit();
    })
  }

  public blockPropertyDetails(id){
    let params={
      id:id,    
    }
    this.MeroDuniyaService.blockPropertyDetails(params).subscribe((response)=>{
      if(response["status"] == 1){
        this.hideSuccessMessage = false;
        this.Message = response["message"];
        setTimeout(()=> {
          this.Message  = null;
          this.hideSuccessMessage = true;
        }, 2000);
      }
      else{
        this.hideErrorMessage = false;
        this.Message = response["message"];
        setTimeout(()=>{
          this.Message  = null;
          this.hideErrorMessage = true;
        }, 3000);
      }
      this.ngOnInit();
    })
  }

  public editPropertyDetails(propertyData){
    this.MeroDuniyaService.setPropertyDetailsDataForEdit(propertyData);
    this.router.navigate(['/editPropertyDetails']);
  }

  public exportProperties(){
    let exportPropertiesData = this.propertyData.filteredData.map(propertyData=>({ 
      "Property Title": propertyData.propertyTitle,
      "Property Price" : propertyData.propertyPrice,
      "Square Feet":propertyData.squareFeet,
      "Number Of Beds" : propertyData.numberOfBeds,
      "Number Of Baths" : propertyData.numberOfBaths,
      "Date Available" : propertyData.dateAvailable,
      "Description":propertyData.description,
      "End Date":propertyData.endDateProperty,
      "Location":propertyData.fullAddress,
    }));
    if(!exportPropertiesData.length){
      exportPropertiesData[0] ={
        "Property Title":"",
        "Property Price" : "",
        "Square Feet":"",
        "Number Of Beds" : "",
        "Number Of Baths" : "",
        "Date Available" : "",
        "Description":"",
        "End Date":"",
        "Location":""
      }
    }
    console.log("data is",this.propertyData.filteredData,exportPropertiesData);
    alasql('SELECT * INTO   CSV("Properties.csv",{headers:true,separator:","}) FROM ?',[exportPropertiesData]);
  }

}
