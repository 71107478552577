import { Component } from '@angular/core';
import { MeroDuniyaService } from '../../mero-duniya.service';
import {Router} from "@angular/router"
import {DefaultLayoutComponent} from '../../containers/default-layout/default-layout.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  constructor (private MeroDuniyaService:MeroDuniyaService,private router: Router,public DefaultLayoutComponent:DefaultLayoutComponent) {}
  public message;
  public loginMessage;
  public disableLoginButton = false;
  public hideSuccessMessage = true;
  public Message;
  public hideErrorMessage = true;

  ngOnInit() {
    let token = this.MeroDuniyaService.getUserToken();
    console.log("token is",token);
    if(token){
      this.router.navigate(['/dashboard'])
    }
    this.loginMessage = JSON.parse(this.MeroDuniyaService.getLoginMessage());
    this.MeroDuniyaService.deleteLoginMessage();
    setTimeout(()=> {
      this.loginMessage  = null;
    }, 5000);
  }

  public login(username,password){
    
    if(!username && !password){
      this.hideErrorMessage = false;
      this.Message = "Please enter email/password";
      setTimeout(()=> {
          this.Message  = null;
          this.hideErrorMessage = true;
      }, 3000);
    }
    else{
      this.DefaultLayoutComponent.hideDefaultSpinner(false);
      var userData = {
        email : username,
        password : password
      }
      this.MeroDuniyaService.login(userData).subscribe((userResponse)=>{
        this.disableLoginButton = false;
        this.DefaultLayoutComponent.hideDefaultSpinner(true);
        if(userResponse['token']){
          this.disableLoginButton = true;
          this.MeroDuniyaService.setProfileDataForEdit(userResponse);
          this.MeroDuniyaService.setUserToken(userResponse['token']);
          this.MeroDuniyaService.setUserID(userResponse['userId']);
          this.MeroDuniyaService.setUserName(userResponse['username']);
          this.MeroDuniyaService.setUserPermissionsData({role:userResponse['role'],permissions:userResponse["privileges"]});
         console.log("users detailssss", userResponse["privileges"]);
          this.router.navigate(['/dashboard']);
          this.MeroDuniyaService.setDashboardMessage("Congratulation ! You are successfully logged in.");
        }
        else{
          this.hideErrorMessage = false;
            this.Message = userResponse["message"];
            setTimeout(()=> {
                this.Message  = null;
                this.hideErrorMessage = true;
            }, 3000);
        }
      })
    }
  }
 }
