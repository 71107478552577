import { Component, OnInit } from '@angular/core';
import { MeroDuniyaService } from '../../../../mero-duniya.service';
import {Router} from "@angular/router";
import {DefaultLayoutComponent} from '../../../../containers/default-layout/default-layout.component';

@Component({
  selector: 'app-create-staff',
  templateUrl: './create-staff.component.html',
  styleUrls: ['./create-staff.component.scss']
})
export class CreateStaffComponent implements OnInit {

  constructor(private MeroDuniyaService:MeroDuniyaService,private router: Router,public DefaultLayoutComponent:DefaultLayoutComponent) { }


  public hideSuccessMessage = true;
  public Message;
  public hideErrorMessage = true;
  public suburb;
  public zipCode;
  public state;
  public streetName;
  public fullAddress;
  public country;
  model: any = {};
  public formData = new FormData;
  public lng;
  public lat;
  public privileges;
  public disableInput;

  ngOnInit() {
    this.privileges = [{privileges:'Manage Users'},{privileges:'Manage Realtors'},{privileges:'Manage Properties'}];
  }

  public getAddress(address){
    var addressObject = this.MeroDuniyaService.getAddress(address);
    this.streetName = ((addressObject[0] && addressObject[0]["long_name"] && addressObject[1] && addressObject[1]["long_name"])?addressObject[0]["long_name"]+addressObject[1]["long_name"]:null);
    this.suburb = ((addressObject[2] && addressObject[2]["long_name"])?addressObject[2]["long_name"]:null);
    this.state = ((addressObject[3] && addressObject[3]["short_name"])?addressObject[3]["short_name"]:null);
    this.zipCode = ((addressObject[4] && addressObject[4]["long_name"])?addressObject[4]["long_name"]:null);
    this.fullAddress = ((addressObject[5])?addressObject[5]:null);
    this.country = ((addressObject[6] && addressObject[6]["long_name"])?addressObject[6]["long_name"]:null);
    this.lng = ((addressObject[8])?addressObject[8]:null);
    this.lat = ((addressObject[7])?addressObject[7]:null);
    this.model.jobAddress = ((addressObject[5])?addressObject[5]:null);
  }

  public createStaff(firstName,lastName,email,contact,password,confirmPassword,assignedPrivileges){
    this.disableInput = true;    
    this.DefaultLayoutComponent.hideDefaultSpinner(false);
    if(!this.lng && !this.lat){
      this.lng = 0;
      this.lat = 0;
    }
    if(password == confirmPassword){
    var params = {
      userType: 'staff',
			firstName : firstName,
			lastName : lastName,
			email : email,
			contact:contact,
			password :password,
      role:'staff',
      permissions: assignedPrivileges
    }
    this.MeroDuniyaService.createStaff(params).subscribe((response)=>{
    this.DefaultLayoutComponent.hideDefaultSpinner(true);
    if(response["status"] == 1){
      this.hideSuccessMessage = false;
      this.Message = response["message"];
      setTimeout(()=> {
        this.Message  = null;
        this.hideSuccessMessage = true;
        this.router.navigate(['/staff']);
      }, 2000); 
    }
    else{
      this.disableInput = false;
      this.hideErrorMessage = false;
      this.Message = response["message"];
      setTimeout(()=>{
        this.Message  = null;
        this.hideErrorMessage = true;
      }, 3000);
    }
  })
 } else {
      this.disableInput = false;
      this.DefaultLayoutComponent.hideDefaultSpinner(true);
      this.hideErrorMessage = false;
      this.Message = 'Password does not match with the confirm password. Please recheck your password';
      setTimeout(()=>{
        this.Message  = null;
        this.hideErrorMessage = true;
      }, 3000);
 }
}



}
