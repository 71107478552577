import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { MeroDuniyaService } from '../../../../mero-duniya.service';
import { ActivatedRoute } from "@angular/router";
import {DefaultLayoutComponent} from '../../../../containers/default-layout/default-layout.component';

@Component({
  selector: 'app-edit-subscription',
  templateUrl: './edit-subscription.component.html',
  styleUrls: ['./edit-subscription.component.scss']
})
export class EditSubscriptionComponent implements OnInit {

  constructor(private MeroDuniyaService:MeroDuniyaService,private router: Router,private route: ActivatedRoute,public DefaultLayoutComponent:DefaultLayoutComponent) { }

  public hideSuccessMessage = true;
  public Message;
  public hideErrorMessage = true;
  model: any = {};
  public disableInput = false;

  ngOnInit() {
    this.model = JSON.parse(this.MeroDuniyaService.getSubscriptionDataForEdit());
  }

  public editSubscription(name,freeDays,price,description){
    var subscriptionPrice = Number.parseFloat(price).toFixed(2);
    console.log("subscriptionPrice",subscriptionPrice)
    var params = {
      _id:this.model._id ,
      name:name,
      freeDays:freeDays,
      price:subscriptionPrice,
      description:description
  }
    this.MeroDuniyaService.editSubscription(params).subscribe((response)=>{
      if(response["status"] == 1){
        this.hideSuccessMessage = false;
        this.Message = response["message"];
        setTimeout( () =>{
          this.Message  = null;
          this.hideSuccessMessage = true;
          this.router.navigate(['/subscription']);
        }, 2000);
        
      }
      else{
        this.disableInput = false;
        this.hideErrorMessage = false;
        this.Message = response["message"];
        setTimeout(()=> {
          this.Message  = null;
        }, 3000);
      }
    })
  }

}
