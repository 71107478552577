import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl,NgModel } from '@angular/forms';

@Directive({
  selector: 'input[numbersOnlyPeriod]',
  providers: [NgModel],
  host: {
    '(ngModelChange)' : 'onInputChange($event)'
  }
})
export class NumberOnlyPeriodDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    // this._el.nativeElement.value = initalValue.replace(/[^0-9\.]*/g, '');
    this._el.nativeElement.value = initalValue.replace(/[^0-9\.]*/g,'').replace('..','.');
    let testLength = this._el.nativeElement.value.split('.').length;
    if(testLength > 2){
      this._el.nativeElement.value = [this._el.nativeElement.value.split('.')[0],this._el.nativeElement.value.split('.')[1]].join('.');
    }
    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
