import { Component } from '@angular/core';
import { MeroDuniyaService } from '../../mero-duniya.service';
import {Router} from "@angular/router"

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent {
  constructor (private MeroDuniyaService:MeroDuniyaService,private router: Router) {}
  ngOnInit() {
    let token = this.MeroDuniyaService.getUserToken();
    if(token){
      this.router.navigate(['/dashboard'])
    }
  }
  public signup(firstName,lastName,email,contact,password,confirmPassword){
    if(password == confirmPassword){
      var userData = {
        userType: 'admin',
        firstName:firstName,
        lastName:lastName,
        email:email,
        role:'admin',
        contact:contact,
        password:password
      }
      this.MeroDuniyaService.signup(userData).subscribe((userResponse)=>{
        if(userResponse['message'] == "User created"){
          window.location.href = '/login';
        }
        else{
          console.log("Some error while creating user");
        }
      })
    }
  }

}
