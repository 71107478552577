import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Papa } from 'ngx-papaparse';
import {Observable,of, from } from 'rxjs';
import { google } from '@google/maps';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
declare var google: any

@Injectable({
  providedIn: 'root'
})


export class MeroDuniyaService {
  userToken;
  hideSpinner;
  
  private chapterDataSource = new BehaviorSubject("default message");
  currentChapterInfo = this.chapterDataSource.asObservable();
   
  public platform: any;
  public geocoder: any;
  constructor(private http: HttpClient,private papa: Papa) {
    
  }
  
  getlanlat2(address: string): Observable<any> {
    console.log('Getting address: ', address);
    let geocoder = new google.maps.Geocoder();
    return Observable.create(observer => {
      geocoder.geocode({
        'address': address
      }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          observer.next(results[0].geometry.location);
          console.log("observer is",observer);
          observer.complete();
        } else {
          console.log('Error: ', results, ' & Status: ', status);
          observer.error();
        }
      });
    });
  }
  public getlanlat3(address):Observable<any> {
    console.log('Getting address: ', address);
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({
      'address': address
    }, (results, status) => {
      console.log("result os",results);
      // if (status == google.maps.GeocoderStatus.OK) {
      //   observer.next(results[0].geometry.location);
      //   console.log("observer is",observer);
      // } else {
      //   console.log('Error: ', results, ' & Status: ', status);
      // }
       this.chapterDataSource.next(JSON.stringify(results))
       return this.currentChapterInfo;
    });
    return this.currentChapterInfo;
  }
  public getlanlat(address,callback) {
    console.log('Getting address: ', address);
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({
      'address': address
    }, (results, status) => {
      console.log("result os",results);
      // if (status == google.maps.GeocoderStatus.OK) {
      //   observer.next(results[0].geometry.location);
      //   console.log("observer is",observer);
      // } else {
      //   console.log('Error: ', results, ' & Status: ', status);
      // }
      callback(results);
      
    });
  }
  
  // public getlanlat(query: string) {
  //   return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + 
  //   query + '&key=AIzaSyBX5asda8UQ5RRjb0zSl9kGXRw_4F7532s');
  // }
  
  setUserToken(token){  
    window.localStorage.setItem("userToken",token);
  }
  
  getUserToken(){
    return window.localStorage.getItem("userToken");
  }
  
  setUserID(userId){  
    window.localStorage.setItem("userId",userId);
  }
  
  getUserID(){
    return window.localStorage.getItem("userId");
  }
  
  setUserName(username){  
    window.localStorage.setItem("userName",username);
  }
  
  getUserName(){
    return window.localStorage.getItem("userName");
  }
  
  deleteToken(){
    localStorage.removeItem("userToken");
  }

  setUserPermissionsData(userPermissions){
    window.localStorage.setItem("userPermissions",JSON.stringify(userPermissions));
  }
  
  getUserPermissionsData(){
    return window.localStorage.getItem("userPermissions");
  }
  
  login(params) {
    return this.http.post("/login",params);
  }
  
  signup(params) {
    return this.http.post("/signup",params);
  }
  showUsers(){
    return this.http.get("/showUsersData");
  }
  logout(){
    return this.http.get("/logout");
  }
  forgotPasswordForWeb(params){
    return this.http.post("/forgotPasswordForWeb",params);
  }
  
  checkResetPasswordToken(params){
    return this.http.post("/checkResetPasswordToken",params);
  }

  resetPassword(params){
    return this.http.post("/resetPassword",params);
  }
  
  deleteLocalData(key){
    localStorage.removeItem(key);
  }
  
  getBaseUrl(){
    return location.origin;
  }
  
  getAddress1(address){
    console.log("address is",address);
    var jobAddress = new Array(7);
    jobAddress[5] = address["formatted_address"];
    if(address.geometry.viewport.ha && address.geometry.viewport.ha["g"]){
      jobAddress[8] = address.geometry.viewport.ha["g"];
    }
    else if(address.geometry.viewport.ja && address.geometry.viewport.ja["g"]){
      jobAddress[8] = address.geometry.viewport.ja["g"];
    }
    else{
      jobAddress[8] = 0;
    }
    if(address.geometry.viewport.da && address.geometry.viewport.da["g"]){
      jobAddress[7] = address.geometry.viewport.da["g"];
    }
    else if(address.geometry.viewport.na && address.geometry.viewport.na["g"]){
      jobAddress[7] = address.geometry.viewport.na["g"];
    }
    else{
      jobAddress[7] = 0;
    }
    address = address["address_components"];
    address.forEach(function(adrs){
      var types = adrs["types"][0];
      switch(types) {
        case "street_number" :
        jobAddress[0] = adrs
        break;
        case "route" :
        jobAddress[1]=adrs
        break;
        case "sublocality_level_1" :
        jobAddress[2]=adrs
        break; 
        case "administrative_area_level_1" :
        jobAddress[3]=adrs
        break; 
        case "postal_code" :
        jobAddress[4]=adrs
        break;
        case "country" :
        jobAddress[6]=adrs
        break; 
      }
    });
    return jobAddress;
    
  }

  
  getAddress(address){
    var jobAddress = new Array(7);
    jobAddress[5] = address["formatted_address"];
    let latlngArray = Object.values(address.geometry.viewport);
    let lat = Object.values(latlngArray[0]);
    let lng = Object.values(latlngArray[1]);
    
    if(lng[0]){
      jobAddress[8] = lng[0];
    }
    else{
      jobAddress[8] = 0;
    }
    
    if(lat[0]){
      jobAddress[7] = lat[0];
    }
    else{
      jobAddress[7] = 0;
    }
    address = address["address_components"];
    address.forEach(function(adrs){
      var types = adrs["types"][0];
      switch(types) {
        case "street_number" :
        jobAddress[0] = adrs
        break;
        case "route" :
        jobAddress[1]=adrs
        break;
        case "sublocality_level_1" :
        jobAddress[2]=adrs
        break; 
        case "administrative_area_level_1" :
        jobAddress[3]=adrs
        break; 
        case "postal_code" :
        jobAddress[4]=adrs
        break;
        case "country" :
        jobAddress[6]=adrs
        break; 
      }
    });
    return jobAddress; 
  }
  
  getProfile(params){
    return this.http.post("/getProfile",params);
  }

  editProfile(params){
    return this.http.post("/editProfile",params);
  }

  setProfileDataForEdit(userData){ 
    console.log("userData", userData) 
    window.localStorage.setItem("userData",JSON.stringify(userData));
  }

  getProfileDataForEdit(){
    // return this.http.get("/getProfileDataForEdit");
     return window.localStorage.getItem("userData");
  }

  
  /** Functions For Property Details */
  editPropertyDetails(params){
    return this.http.post("/editPropertyDetails",params);
  }
  
  getPropertyDetailsDataForAdmin(params){
    return this.http.post("/getPropertyDetailsDataForAdmin",params);
  }
  
  deletePropertyDetails(params){
    return this.http.post("/deletePropertyDetails",params);
  }
  
  setPropertyDetailsDataForEdit(propertyData){  
    window.localStorage.setItem("propertyData",JSON.stringify(propertyData));
  }
  
  getPropertyDetailsDataForEdit(){
    return window.localStorage.getItem("propertyData");
  }
  
  unblockPropertyDetails(params){
    return this.http.post("/unblockPropertyDetails",params);
  }
  blockPropertyDetails(params){
    return this.http.post("/blockPropertyDetails",params);
  }


  /** End of Functions For Property Details */
  setThankyouMessage(thankyouMessage){  
    window.localStorage.setItem("thankyouMessage",thankyouMessage);
  }
  getThankyouMessage(){
    return window.localStorage.getItem("thankyouMessage");
  }

/** Functions For User Details */
  getUserDetails(params){
    return this.http.post("/getUserDetails",params);
  }
  
  deleteUserDetails(params){
    return this.http.post("/deleteUserDetails",params); 
  }
  
  setUserDetailsDataForEdit(userData){  
    window.localStorage.setItem("userData",JSON.stringify(userData));
  }
  
  getUserDetailsDataForEdit(){
    return window.localStorage.getItem("userData");
  }
  
  unblockUserDetails(params){
    return this.http.post("/unblockUserDetails",params);
  }
  
  blockUserDetails(params){
    return this.http.post("/blockUserDetails",params);
  }

  editUserDetails(params){
    return this.http.post("/editUserDetails",params);
  }
  /** End of Functions For User Details */

  
  /****** Start of the functions for the staffs *******/
  getUsers(params){
    return this.http.post("/getUsers",params);
  }
  
  blockUser(params){
    return this.http.post("/blockUser",params); 
  }
  
  setStaffDetailsDataForEdit(userData){  
    window.localStorage.setItem("userData",JSON.stringify(userData));
  }
  
  getStaffDetailsDataForEdit(){
    return window.localStorage.getItem("userData");
  }
  
  unBlockUser(params){
    return this.http.post("/unBlockUser",params);
  }
  
  getStaff(params){
    return this.http.post("/getStaff",params);
  }

  createStaff(params){
    return this.http.post("/createStaff",params);
  }

  editStaff(params){
    return this.http.post("/editStaff",params);
  }

  deleteStaff(params){
    return this.http.post("/deleteStaff",params);
  }

  /****** End of the functions for the staffs *******/

/****** Start of the functions for the Subscription *******/

editSubscription(params){
  return this.http.post("/editSubscription",params); 
}

setSubscriptionDataForEdit(subscriptionData){  
  window.localStorage.setItem("subscriptionData",JSON.stringify(subscriptionData));
}

getSubscriptionDataForEdit(){
  return window.localStorage.getItem("subscriptionData");
}

deleteSubscription(params){
  return this.http.post("/deleteSubscription",params);
}

unblockSubscription(params){
  return this.http.post("/unblockSubscription",params);
}

blockSubscription(params){
  return this.http.post("/blockSubscription",params);
}

getSubscription(params){
  return this.http.post("/getSubscription",params);
}
/****** End of the functions for the Subscription *******/


/** Functions For Realtor Details */
getRealtorDetails(params){
  return this.http.post("/getRealtorDetails",params);
}

deleteRealtorDetails(params){
  return this.http.post("/deleteRealtorDetails",params); 
}

setRealtorDetailsDataForEdit(userData){  
  window.localStorage.setItem("userData",JSON.stringify(userData));
}

getRealtorDetailsDataForEdit(){
  return window.localStorage.getItem("userData");
}

unblockRealtorDetails(params){
  return this.http.post("/unblockRealtorDetails",params);
}

blockRealtorDetails(params){
  return this.http.post("/blockRealtorDetails",params);
}

editRealtorDetails(params){
  return this.http.post("/editRealtorDetails",params); 
}

createRealtorDetails(params){
  return this.http.post("/createRealtorDetails",params);
}
/** End of Functions For Realtor Details */

getNumberOfUsers(){
    return this.http.get("/getNumberOfUsers");
  }

getNumberOfRealtors(){
  return this.http.get("/getNumberOfRealtors");
}

getNumberOfProperties(){
  return this.http.get("/getNumberOfProperties");
}

/*** Functions for count for the dashboard ***/

changePassword(params){
  return this.http.post("/changePassword",params); 
}

getSubscriedUserForWeb(){
  return this.http.get("/getSubscriedUserForWeb"); 
}

/*** Messages ***/

  setLoginMessage(loginMessage){  
    window.localStorage.setItem("loginMessage",JSON.stringify(loginMessage));
  }
  getLoginMessage(){
    return window.localStorage.getItem("loginMessage");
  }

  setDashboardMessage(dashboardMessage){  
    window.localStorage.setItem("dashBoardMessage",dashboardMessage);
  }
  getDashboardMessage(){
    return window.localStorage.getItem("dashBoardMessage");
  }
  deleteLoginMessage(){
   window.localStorage.removeItem("loginMessage");
  }
  deleteDashboardMessage(){
    window.localStorage.removeItem("dashBoardMessage");
   }

/*** Messages***/


/*** End Of Functions for count for the dashboard ***/

  CSVDatatoObject(files: FileList,callback){
    // console.log("csv file data",files);
    // let file : File = files[0]; 
    // let reader: FileReader = new FileReader();
    // reader.readAsText(file);
    // reader.onload = (e) => {
    //   console.log("readastest",file);
    //   let csv: string = reader.result as string;
    //   console.log("csv is",csv);
    //   callback(this.extractData(csv));
    // } 
    this.papa.parse(files[0],{
      complete: (csv) => {
        console.log('Parsed: ', csv);
        callback(this.convertCSVToObject(csv.data));
      }
    });
  }
  
  public extractData(data) { // Input csv data to the function
    let csvData = data;
    let allTextLines = csvData.split(/\r\n|\n/);
    let headers = allTextLines[0].split(',');
    let lines = [];
    
    for ( let i = 0; i < allTextLines.length; i++) {
      let data = allTextLines[i].split(',');
      console.log("dsadjkasjdas",allTextLines,data);
      if (data.length == headers.length) {
        let tarr = [];
        for ( let j = 0; j < headers.length; j++) {
          tarr.push(data[j]);
        }
        lines.push(tarr);
      }
    }
    return this.convertCSVToObject(lines);
  }
  
  public convertCSVToObject(lines){
    var ob = {};
    var csvData = [];
    for(var i=1;i<lines.length ;i++){
      for(var j=0;j<lines[0].length ;j++){
        ob[lines[0][j]] = lines[i][j];
      }
      csvData.push(JSON.parse(JSON.stringify(ob)));
    }
    console.log("csv data is",csvData);
    csvData.splice(-1,1);
    return csvData;
  }
  
  

  previewUploadedImage(files,callback) {
    console.log("file is",files);
    if (files.length === 0)
      callback();
 
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      callback({Status:0,Message:"Please Upload a Valid Image File.",fileName:files[0]["name"]}); 
      return ;
    }
    else if (files[0]["size"] > 5242880 ) {
      callback({Status:0,Message:"Please Upload Image with size less than 5 MB",fileName:files[0]["name"]}); 
      return ;
    }

    var reader = new FileReader();
    // this.imagePath = files;
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      callback({Status:1,ImageURL:reader.result,fileName:files[0]["name"]}); 
    }
  }
}




