import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';


import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { ProfileComponent } from './views/profile/profile.component';
import { PropertyDetailsComponent } from './views/admin/property/property-details/property-details.component';
import { EditPropertyDetailsComponent } from './views/admin/property/edit-property-details/edit-property-details.component';
import { UserDetailsComponent } from './views/admin/Users/user-details/user-details.component';
import { ViewUserDetailsComponent } from './views/admin/Users/view-user-details/view-user-details.component';
import { RealtorDetailsComponent } from './views/admin/realtors/realtor-details/realtor-details.component';
import { ViewRealtorDetailsComponent } from './views/admin/realtors/view-realtor-details/view-realtor-details.component';
import { CreateStaffComponent } from './views/admin/staff/create-staff/create-staff.component';
import { StaffComponent } from './views/admin/staff/staff/staff.component';
import { EditStaffComponent } from './views/admin/staff/edit-staff/edit-staff.component';
import { ViewStaffComponent } from './views/admin/staff/view-staff/view-staff.component';
import { SubscriptionComponent } from './views/admin/subscription/subscription/subscription.component';
import { EditSubscriptionComponent } from './views/admin/subscription/edit-subscription/edit-subscription.component';
import { TransactionReportComponent } from './views/admin/transacton-report/transaction-report/transaction-report.component';
import { ChangePasswordComponent } from './views/change-password/change-password/change-password.component';
import { ThankyouPageComponent } from './views/thanks_page/thankyou-page/thankyou-page.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'forgotPassword',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Password'
    }
  },
  {
    path: 'thankYou',
    component: ThankyouPageComponent,
    data: {
      title: 'Thank You'
    }
  },
  {
    path: 'resetPassword/:resetToken',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset Password'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'editProfile',
        component: ProfileComponent,
        data: {
          title: 'Edit Profile'
        }
      },
      {
        path: 'propertyDetails',
        component: PropertyDetailsComponent,
        data: {
          title: 'Property Details'
        }
      },
      {
        path: 'editPropertyDetails',
        component: EditPropertyDetailsComponent,
        data: {
          title: 'Edit Property Details'
        }
      },
      {
        path: 'userDetails',
        component: UserDetailsComponent,
        data: {
          title: 'User Details'
        }
      },
      {
        path: 'viewUserDetails',
        component: ViewUserDetailsComponent,
        data: {
          title: 'View User Details'
        }
      },
      {
        path: 'realtorDetails',
        component: RealtorDetailsComponent,
        data: {
          title: 'Realtor Details'
        }
      },
      {
        path: 'viewRealtorDetails',
        component: ViewRealtorDetailsComponent,
        data: {
          title: 'View Realtor Details'
        }
      },
      {
        path: 'createStaff',
        component: CreateStaffComponent,
        data: {
          title: 'Create Staff'
        }
      },
      {
        path: 'staff',
        component: StaffComponent,
        data: {
          title: 'Staff Details'
        }
      },
      {
        path: 'editStaff',
        component: EditStaffComponent,
        data: {
          title: 'Edit Staff Details'
        }
      },
      {
        path: 'viewStaff',
        component: ViewStaffComponent,
        data: {
          title: 'View Staff Details'
        }
      },
      {
        path: 'subscription',
        component: SubscriptionComponent,
        data: {
          title: 'Subscription Details'
        }
      },
      {
        path: 'editSubscription',
        component: EditSubscriptionComponent,
        data: {
          title: 'Edit Subscription'
        }
      },
      {
        path: 'transactionReport',
        component: TransactionReportComponent,
        data: {
          title: 'Transaction Reports'
        }
      },
      {
        path: 'changePassword',
        component: ChangePasswordComponent,
        data: {
          title: 'Change Password'
        }
      }
      
    ]
  },
  

  { path: '**', component: P404Component }
];


@NgModule({
  imports: [ RouterModule.forRoot(routes)],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
